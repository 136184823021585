.tablez{
  width: 100%;
  overflow-x: auto;
    .table{
      width: 1200px;
        padding: 1.5rem;
      overflow-x: scroll;
    }
    tr{
        td{
             padding-top: 1.5rem;
             padding-bottom: 1.5rem;
        }
    }
}