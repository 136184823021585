#GeneralNavBar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 100px;

    .acting {
        display: inline-block;
        border-bottom: 3px solid #128c7e;
        font-weight: 600;
        color: #128c7e;
    }

    a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.521);
        font-family: "Roboto";
        font-style: normal;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        width: 7rem;
        margin-left: 10px;
        padding: 0.5rem;

        li {
            list-style: none;

        }
    }

    ul {
        list-style: none;
        padding: 0;
    }
}

#MobileSideBar {
    height: 100vh;
    width: 300px;
    position: fixed;
    top: 66px;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 1;
    padding: 1rem;
    background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);

    display: flex;
    flex-direction: column;
    gap: 25px;
    a{
        text-decoration: none;
        color: #232323;
    }
}
.icon{
    color: white !important;
}
.makeSpacing{
    padding: 10px 20px;

    .act{
        border-bottom: 2px solid #0E9A6E;
        color: #0E9A6E;
    }
}
.ul{
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
    0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
    ul{

        li{
            list-style: none;
        }
    }
}
.userImag{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    img{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #6d7d8b;
    }
    h2{
        font-size: 20px;
        font-weight: 500;
    }
}