#Online {
  .online-contact-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    .left {
      margin-bottom: 1rem;
      .servicesHolder {
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
          color: #1c1e21;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        .listHolder {
          width: 70%;
          display: flex;
          flex-direction: column;

          .box {
            margin-left: 1rem;
            width: 100%;
            display: flex;
            gap: 10px;
            height: 100%;
            align-items: center;
            gap: 10px;
            margin-bottom: 0.5rem;

            img {
              height: fit-content;
              width: fit-content;
            }

            span {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 25px;
              color: #707173;
            }
          }

          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #707173;
          }
        }
      }
    }

    
    .righFormHolder {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      height: 100%;
      padding: 2rem;
      h1 {
        text-align: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 38px;
        color: #1c1e21;
      }

      .formHolder {
        margin-top: 1.5rem;
        width: 100%;

        form {
          .mail {
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }

            input {
              padding: 12px;
              border: 0;
              outline: none;
              width: 100%;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;

              &::placeholder {
                color: #adb5bc;
                font-size: 16px;
                line-height: 163.15%;
              }
            }
          }

          .a1 {
            margin-top: 10px;

            label {
              display: block;
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }

            select.role {
              margin-bottom: 0.5rem;
              width: 100%;
              border: 0;
              padding: 14px;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;
              color: #adb5bc;
              font-size: 16px;
            }
          }

          .pass {
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }

            input {
              padding: 12px;
              border: 0;
              outline: none;
              width: 100%;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;

              &::placeholder {
                color: #adb5bc;
                font-size: 16px;
                line-height: 163.15%;
              }
            }
          }

          .rememberMe {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 9px;

            span {
              font-size: 14px;
              line-height: 163.15%;
              display: flex;
              align-items: center;
              color: #6d7d8b;
            }
          }

          .button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              color: #ffffff;
              margin-top: 15px;
              border: 0;
              width: 100%;
              outline: none;
              align-items: center;
              padding: 10px;
              gap: 10px;
              width: 525px;
              height: 52px;
              background: #128c7e;
              border-radius: 8px;

              a {
                text-decoration: none;
                color: #ffffff;
              }
            }
          }

          .dont {
            margin-top: 1rem;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 163.15%;

            color: #25313c;
          }
        }
      }
    }
  }
}