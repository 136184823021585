#RealizedLosses {
    height: 100vh;
    background-color: #faf6f6fb;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    .titleFormat {
        display: flex;
        gap: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        h1 {
            /* Header/H1 48px */
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 800;
            font-size: 48px;
            line-height: 56px;
            font-feature-settings: 'liga' off;

            /* Color/Text/Grey/100 */
            color: #1C1E21;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 38px;

            /* identical to box height */
            font-feature-settings: 'liga' off;

            /* Color/Text/Grey/100 */
            color: #1C1E21;
        }
    }

    .contentRep {
        .boxex {
            border: 1px solid rgba(0, 0, 0, 0.377);
            padding: 1rem;
            border-radius: 10px;

            .innerBoxex {
                display: flex;
                justify-content: space-between;

                ul {
                    padding: 0;
                    list-style: none;
                }
            }
        }
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #46484A;
    }

    .repHolder {
        height: 90vh;
        background-color: white;
        width: 100%;
        padding: 0;
        overflow: scroll;

        .header {
            padding: 1rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            background: #ffffff;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
                0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);

            .left {
                h1 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 38px;
                    color: #1C1E21;
                }
            }

            .right {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                button {
                    padding: 10px;
                    outline: none;
                    border: 1px solid #128C7E;
                    background-color: transparent;
                    color: #128C7E;
                    border-radius: 5px;

                }

                .btnFour {
                    background-color: #128C7E;
                    color: #ffffff;
                }
            }
        }

        .period {
            margin-top: 1rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 20px;
flex-wrap: wrap;
            .dropdown {
                border: 1px solid #1C1E21;
                border-radius: 7px;
            }

            span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 163.15%;

                /* or 26px */
                display: flex;
                align-items: center;

                /* Color/Text/Grey/80 */
                color: #46484A;
            }

            .left {
                display: flex;
                gap: 10px;
                align-items: center;

                input {
                    padding: 8px;
                    border-radius: 7px;
                    border: 1px solid #1C1E21;
                }
            }

            .right {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .table {
            padding: 2rem;
            margin-top: 1rem;
            overflow: scroll;
            overflow-y: scroll;

            table {
                td {
                    border: 1px solid #adadad;
                }

                thead {
                    padding: 10px;
                    text-align: center;
                    background-color: #E3DFDF;
                }

                .bd {
                    border-top: 2px solid black;
                }
            }
        }

        .contentHolder {
            width: 80%;
            margin: 0 auto;
            border: 1px solid rgba(0, 0, 0, 0.274);

            .classHeader {
                width: 100%;
                background-color: #128C7E;
                height: 70px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .upperHolder {

                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    p {
                        margin-bottom: 0;
                        padding-right: 1rem;
                    }
                }

                .downHolder {}
            }

            .date {
                display: flex;
                border: 1px solid rgba(0, 0, 0, 0.274);

                p {
                    margin-bottom: 0;
                }

                .left {
                    flex: 1.5;
                    border-right: 1px solid;
                    display: flex;
                    align-items: center;

                    p {
                        margin-bottom: 0 !important;
                    }
                }

                .right {
                    padding: 5px;
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .class {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.274);

                        h6 {
                            text-align: center;
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }

                    span {
                        border: 1px solid rgba(0, 0, 0, 0.274);
                        height: 39px;
                    }
                }
            }
        }

        .nextBtn {
            width: 90%;
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;
            margin-bottom: 1rem;

            a {
                button {
                    outline: none;
                    border: none;
                    color: white;
                    background-color: #128C7E;
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

        }

        .videoContent {
            width: 80%;
            margin: 0 auto;
            padding: 2rem;

            h2 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 47px;

                /* identical to box height */
                display: flex;
                align-items: center;
                text-align: center;
                font-feature-settings: 'liga' off;

                /* Color/UI/Grey/100 */
                color: #1C1E21;
            }

            .imgContainer {
                img {
                    width: 100%;
                }
            }
        }
    }
}