#GeneralTable {
  overflow-y: scroll;
  width: 100%;

  .table {
    margin-top: 1rem;
    overflow-y: scroll;
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid #adadad;

  }

  thead {
    background-color: #D2D2D3;
  }



  tr {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    td {
      padding: 1rem;
      // text-align: center;
    }

    .act {

      color: #0E9A6E;
    }

    .inact {

      color: #707173;
    }

    .span {
      border: 1px solid #da7a0a;
      padding: 3px;
      border-radius: 10px;
      color: #da7a0a;
    }

    .one {
      border: 1px solid #F54765;
      padding: 3px;
      border-radius: 10px;
      color: #F54765;
    }
  }
}