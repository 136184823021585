  #Home {
  .home {
    background-image: url("../assets/Landing\ page.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    width: 100%;
    .mainContent {
      margin-top: 6rem;
      .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          width: 55%;
          text-align: center;
          font-weight: 800;
          font-size: 45px;
          line-height: 60px;
        }
      }
      .subTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        p {
          width: 45%;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          line-height: 29px;
        }
      }
      .buttonHolder {
        width: 100%;
        display: flex;
        justify-content: center;

        button.btnOne {
          border: 0;
          margin-top: 0.5rem;
          width: 30%;
          background-color: #128c7e;
          padding: 10px;
          border-radius: 8px;
          color: white;
        }
      }
    }
  }

  .windowHolder {
    position: relative;
    top: -12rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .BringFinancials {
    position: relative;
    top: -4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    h1 {
      color: #25313c;
      font-weight: 800;
      font-size: 40px;
      line-height: 50px;
    }
    p {
      width: 37%;
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #707173;
    }
  }
  .cardBody {
    margin-bottom: 5rem;
  }
  .planHolder {
    .plans {
      background-image: url("../assets/Landing\ page1.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 800px;
      width: 100%;
      .planTitleHolder {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1 {
          font-style: normal;
          font-weight: 800;
          font-size: 40px;
          line-height: 50px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #25313c;
          margin-bottom: 1rem;
        }
        p {
          width: 600px;
          font-weight: 600;
          font-size: 18px;
          line-height: 29px;
          text-align: center;
          color: #6d7d8b;
        }
      }
    }
  }
  .priceHolder {
    width: 100%;
    position: relative;
    top: -600px;
  }
  .tryDemo {
    position: relative;
    top: -400px;
    width: 100%;
  
    background-size: cover;
    background-image: url("../assets/Vector.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #128c7e;
      }
      h1 {
        font-weight: 800;
        font-size: 40px;
        line-height: 50px;
        width: 70%;
        color: #000000;
        margin-bottom: 1rem;
      }
    }

    .left {
      background-image: url("../assets/Countries Background.png");
      height: 50vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 100%;
      }
      span {
        display: flex;
        justify-content: flex-end;
        margin-right: 2rem;
        margin-top: 1rem;
      }
    }
  }       
  .faq {
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    position: relative;
    top: -230px;
  }
  .faqHolder {
    position: relative;
    top: -150px;

    .button:focus:not(:focus-visible) {
      outline: 0;
    }
    .accordion-button .collapsed {
      outline: none !important;
    }
    .accordion-button:not(.collapsed) {
      color: var(--bs-accordion-active-color);
      background-color: transparent !important;
      box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
        var(--bs-accordion-border-color);
    }
    .accordion-button {
      border-top-left-radius: none !important;
      border-top-right-radius: none !important;
    }
    .accordion-item {
      color: var(--bs-accordion-color);
      background-color: var(--bs-accordion-bg);
      border: none !important;
      border-bottom: 1px solid #000 !important;
      padding: 10px;
      border-radius: 0;
    }
  }
}
