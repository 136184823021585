#Intable {
  .heading {
    background: #EBECEE;
    width: 1200px;
    overflow: scroll;
  }
table{
  width: 1200px;
  overflow: scroll;
}
  th {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 163.15%;
    text-align: center;
    padding: 1rem;
  }

  tr {
    &:hover {
      background-color: #d2d2d3;
      color: white;
      transition: ease-in-out 0.5s;
      cursor: pointer;
    }
  }

  td {
    justify-content: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 163.15%;
    text-align: center;
    padding: 0.5rem;
  }
}

// .act {
//   border: 1px solid #d2d2d3;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }