#Footer {
  background: #273443;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  color: white;
  .footerContent {
    h1 {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: #fff;
      width: 80%;
      
    }
    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        font-size: 14px;
        line-height: 21px;
        color: #fff;
      }
    }
    .iconHolder {
      display: flex;
      gap: 15px;
    }
  }
  .lasting {
    margin-top: 4rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #fff;
  }
}
