#HomeDashboard {
    display: flex;
    height: 100vh;
}

#Sidebar {
    flex: 1.5;
    background-color: #273443;
    overflow-y: scroll;

    a {
        text-decoration: none;
        color: white;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }

    .icons {
        color: white;
        margin-right: 10px;

    }

    hr {
        color: #455465;
    }

    .activate {
        background-color: #D2EFEC;
        padding: 5px;
        width: 90%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        color: #273443;

        a {
            color: #273443;
        }

        .icons {
            color: #273443;

        }
    }

    .ones {
        padding-left: 20px;
    }

    .navBarHolder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 10px;

        .fesSection {
            img {
                margin-top: 10px;
                margin-left: 20px;
                margin-bottom: 20px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;


            li {

                &:hover {

                    transition: all 1s;
                    width: 90%;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;

                    a {
                        color: #788a9e;
                    }

                    .icons {
                        color: #273443;

                    }
                }
            }

        }


    }
}

#MainContent {
    flex: 6;
    padding: 20px;
    height: 100vh;
    overflow-y: scroll;

    #cashNav {
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: space-between;
        ul{
            background-color: #EBECEE;
            padding: 0;
            display: flex;
            a{
                text-decoration: none;
                color: #6D7D8B;
                padding: 10px;
            }
            .act{
                background-color: white;
                border-bottom: 2px solid #128C7E;
            }
        }
        .help{
            button {
                    background: #FFFFFF;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                    padding: 5px 20px;
                    outline: none;
                    border: 1px solid #DA7A0A;
                    color: #DA7A0A;
                }
        }
    }

    #plan {
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        margin-top: 20px;
        padding: 1rem;

        .divs {
            margin-top: 1rem;
            display: flex;

            .btn {
                button {
                    border-radius: 8px;
                    outline: none;
                    border: 1px solid #128C7E;
                    color: #128C7E;
                    padding: 10px 15px;
                    background: white;
                }
            }

            .leftBar {
                padding: 1rem;
                flex: 1.2;
                border-right: 2px solid #D2D2D3;

                .activate {
                    color: #128C7E;
                }

                ol {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    li {
                        display: flex;
                        flex-direction: column;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 25px;

                        span {
                            padding: 5px 10px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #46484A;
                        }
                    }
                }
            }

            .rigthContents {
                flex: 3;
                padding: 1rem;

                .content {
                    h3 {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: 1rem;
                        color: #128C7E;

                    }

                    .leftDownload {
                        display: flex;
                        justify-content: flex-end;
                        gap: 10px;

                        button {
                            outline: none;
                            border: none;
                            background-color: white;
                            padding: 10px 15px;

                        }

                        .one {
                            background: #128C7E;
                            border-radius: 8px;
                            color: white;
                        }

                        .two {
                            color: #128C7E;
                            border: 1px solid #128C7E;
                            border-radius: 8px;
                        }

                    }

                    .content_details {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;

                        .spaceX {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #1C1E21;
                            margin-left: .5rem;
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 23px;
                            padding-left: 10px;
                            color: #1C1E21;
                        }

                        .edit {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            button {
                                background: #FFFFFF;
                                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                                padding: 5px 20px;
                                outline: none;
                                border: 1px solid #DA7A0A;
                                color: #DA7A0A;
                            }
                        }
                    }
                }
            }
        }
    }

    .cardHolder {
        display: flex;
        padding: 30px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .fes {
                width: 50px;
                border-radius: 50%;
                height: 50px;
                background: #D2EFEC;
            }

            h1 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 52px;
                line-height: 61px;

                /* identical to box height */
                display: flex;
                align-items: center;
                text-align: center;

                /* Color/Green/500 */
                color: #128C7E;
            }

            height: 300px;
            width: 400px;
            background: #FFFFFF;
            padding: 10px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
            0px 2px 2px rgba(0, 0, 0, 0.08),
            0px 4px 8px rgba(0, 0, 0, 0.12);
        }
    }
}


#NavBoard {

    .content {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        background: #FFFFFF;
        /* Color/UI/Grey/20 */

        border: 1px solid #D2D2D3;
        /* Container Shadow */

        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);

        .fes {
            display: flex;
            align-items: center;
            gap: 10px;

            span.dropdown {
                background: #FFFFFF;
                border: 1px solid #D2D2D3;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                border-radius: 8px;
                padding: 10px;
            }
        }

        .las {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .icon {
            color: gray;
        }

        .imgs {
            background-color: #273443;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            outline: none;
            border: none;

            img {
                height: 30px;
                width: 30px;
                object-fit: cover;
                border: none;
                outline: none;
            }
        }
    }
}


.search {
    background-color: transparent;
    display: flex;
    align-items: center;

    .icon {
        background-color: #128c7e;
        color: white;
        font-size: 37px;
    }

    span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin-right: 0.5rem;
        color: #000000;
    }

    div {
        border: 1px solid #aaa7a7;

        input {
            border: none;
            outline: none;
            padding: 5px;
        }
    }
}


.settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 2rem;

    span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
    }

    .iconz {
        color: #aab3b1;
    }

    .dropdown {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
            padding: 8px;
            width: 200px;
            outline: none;
            padding-left: 15px;
            padding-right: 15px;
            border: 1px solid rgb(99, 99, 99) !important;
        }
    }
}

.las {
    button {
        padding: 10px 20px;
        gap: 10px;
        outline: none;
        width: 185px;
        height: 52px;
        color: white;
        background: #128C7E;
        border-radius: 8px;

        a {
            text-decoration: none;
            color: white;
        }

        &:hover {
            background-color: #011614 !important;
        }
    }
}

#FooterBoard {
    .footer {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        color: #6D7D8B;

        ul {
            display: flex;
            gap: 20px;
            list-style: none;
            padding: 0;
        }
    }
}

.paginate {
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    a {
        color: gray;
    }

    .acts {
        a {
            color: #00c5ae;
        }
    }

    nav {
        display: flex;
        flex-direction: column;

        span {
            margin-left: 40%;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            /* identical to box height, or 136% */

            /* Color/Text/Grey/100 */
            color: #1c1e21;
        }
    }

    .btn {
        height: 50px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        border: 1px solid #0e9a6e;
        color: #0e9a6e;
    }
}