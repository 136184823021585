#CustomersDashBoard {
  padding-bottom: 2rem;

  .fsLayer {
    padding-top: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 30px;

    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 47px;
    }

    .navHolder {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
     

      .boxes {
        width: 120px;
        height: 120px;
        border: 1px solid #aaa7a7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        a {
          text-decoration: none;
          color: #000;
          font-family: "Roboto";
          font-style: normal;
          font-size: 16px;
          line-height: 25px;
        }
      }

      .remove {
        border: 1px solid #aaa7a7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .act {
        background: #128c7e;

        a {
          color: #ffffff;
        }
      }
    }
  }

  .secondLayer {
    padding-bottom: 4rem;

    .left {
      padding-top: 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;

      .Top {
        padding-top: .5rem;
        padding-bottom: .5rem;

        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;

        }
      }

      .heading {
        display: flex;
        justify-content: space-between;

        h1 {
          margin-top: 4px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;

        }

        .date {
          display: flex;
          align-items: center;
          gap: 10px;

          .inputDate {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
          }
        }

        .setting {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
          }

          .dropdown {
            button {
              outline: none;
              padding-left: 15px;
              padding-right: 15px;
              border: 1px solid rgb(99, 99, 99);
            }
          }
        }
      }
    }
    .right {
      .innerHolder {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 1rem;
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
          0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px;

        .heading {
          h1 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            /* Color/Text/Grey/100 */
            color: #1c1e21;
          }
        }

        .sec-one {
          gap: 50px;

          p.one {
            text-decoration: underline;
          }
        }

        .secTwo {
          .title {
            background: #ebecee;
            width: 47%;

            h1 {
              padding-left: 8px;
              padding-top: 10px;
              padding-bottom: 10px;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
            }
          }

          .para {
            display: flex;
            gap: 50px;

            p.one {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .tabling {
    padding: 2rem;
    background: #ffffff;
    border-radius: 8px;

    .heading {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      h1 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
      }

      .setting {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
        }

        .dropdown {
          button {
            outline: none;
            padding-left: 15px;
            padding-right: 15px;
            border: 1px solid rgb(99, 99, 99);
          }
        }
      }
    }
  }

  .Attendence {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
    margin-top: 3rem;

    .header {
      display: flex;
      flex-wrap: wrap;
      background: #273443;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      span {
        color: white;
      }
    }

    .nav {
      .act {
        background: #FFFFFF;
        border-bottom: 2px solid #128c7e;
        padding: 10px 20px;
      }

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        background-color: #EBECEE;

        li {
          padding: 10px;

          span {
            background-color: #0E9A6E;
            color: white;
            border-radius: 100%;
            padding: 2px;
            padding-left: 3px;
            padding-right: 3px;
          }
        }
      }
    }

    .contentHolders {
      justify-content: space-between;
      padding: 10px;
      flex-wrap: wrap;
      width: 100%;
      height: 250px;
      overflow: scroll;
      align-items: center;

      .title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: #273443;
        color: white;
        padding: 5px;
        width: 200px;
      }

      .body {
        ul {
          list-style: none;
          padding: 0;
          padding: 3px;

          li {
            font-style: normal;
            font-weight: 400;
            color: #1C1E21;

            span {
              background: #DA7A0A;
              color: white;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 20px;
              margin-right: 5px;
            }

            .success {
              background: #0E9A6E;
              color: white;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 20px;
              margin-right: 5px;
            }

            .pending {
              background: #707173;
              color: white;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 20px;
              margin-right: 5px;
            }

            .reject {
              background: #F54765;
              color: white;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 20px;
              margin-right: 5px;
            }
          }
        }
      }

      .boxHolder {

        .box {
          display: flex;
          width: 100%;
          border: 1px solid #EBECEE;
          padding: 10px;
          gap: 10px;
          margin-top: 5px;

          .img {
            width: 70px;
            height: 70px;
            border-radius: 100%;
            border: 1px solid #EBECEE;

            img {
              width: 70px;
              height: 70px;
              border-radius: 100%;
            }
          }

          .left {
            p {
              margin-bottom: 0;
            }
          }
        }
      }

    }
  }

  .budgetNavHolder {
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .act {
      background: #FFFFFF;
      border-bottom: 2px solid #128c7e;
      padding: 10px 20px;
      
    }

    .upperNav {
      overflow-x: scroll;
      width: 100%;
      ul {
        width: 100vw;
        padding: 0;
        display: flex;
        list-style: none;
        gap: 25.8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.164);
        background-color: rgba(0, 0, 0, 0.082);

        a {
          color: #000;
          text-decoration: none;
          li {
            padding: 5px 10px;
          }
        }

      }
    }
  }
.one{
  overflow: hidden !important;
}
  .chart {
    margin-top: 2rem;
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
      0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);

    .chartNav {
      display: flex;
      justify-content: space-between;

      button {
        border: 1px solid #46484A;
        color: #46484A;
        outline: none;
        padding: 10px;
        border-radius: 5px;
      }
    }

    .chartHolder {}
  }

  .xl {
    background-color: #c5c5c5;
    width: 200px;
    color: #1C1E21;
    font-weight: 900;
    border: none !important;
  }

  .table {
   
    border-radius: 8px;
    padding: 1rem;
    margin-top: 2rem;

    .addBtn {
      display: flex;
      justify-content: space-between;

      button {
        background: #0E9A6E;
        color: white;
        outline: none;
        border: none;
        padding: 10px;
        border-radius: 8px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    color: #c2c3c4;

    ul {
      display: flex;
      gap: 20px;
      list-style: none;
      padding: 0;
    }
  }
}