#Features {
  .cards {
    .row {
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
      .cardHolder {
        margin: 0.5rem;
        padding: 0.5rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 31%;
        background-color: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
          0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        .title {
          h1 {
            font-size: 22px;
            font-weight: 700;
            color: #25313c;
            margin-top: 0.5rem;
          }
        }
        .list {
          ul {
            display: flex;
            flex-direction: column;
            gap: 7px;
            li {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 163.15%;
            }
          }
        }
        .buttonHolder {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            outline: none;
            border: 1px solid #25313c71;
            color: #128c7e;
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            border-radius: 8px;
            padding: 10px;
            width: 50%;
          }
        }
      }
    }
  }

  .divider {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .tryDemo {
      width: 100%;
      flex-direction: column;
     
      background-size: cover;
      background-image: url("../assets/Vector.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #128c7e;
        }
        h1 {
          font-weight: 800;
          font-size: 40px;
          line-height: 50px;
          width: 70%;
          color: #000000;
          margin-bottom: 1rem;
        }
      }
    }

    .left {
      background-image: url("../assets/Countries Background.png");
      height: 50vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 100%;
      }
      span {
        display: flex;
        justify-content: flex-end;
        margin-right: 2rem;
        margin-top: 1rem;
      }
    }
  }
  .qAholder {
    margin-top: 2rem;
    margin-bottom: 4rem;
    .faq {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;
    }
    .faqHolder {
      .button:focus:not(:focus-visible) {
        outline: 0;
      }
      .accordion-button .collapsed {
        outline: none !important;
      }

      .accordion-item {
        border: none !important;
        border-bottom: 1px solid #000 !important;
        padding: 10px;
        border-radius: 0;
      }
    }
  }
}
