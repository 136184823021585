#Partners {
  .partnersHeading {
    padding-top: 3rem;
  }

  .formHolder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    .innerFormHolder {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
width: 100%;
      .formHolder {
        form {
          padding: 2rem;

          h1 {
            text-align: center;
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 800;
            font-size: 48px;
            line-height: 60px;
            color: #1c1e21;
          }

          .mail {
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }

            input {
              margin-top: 5px;
              margin-bottom: 5px;
              padding: 12px;
              border: 0;
              outline: none;
              width: 100%;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;

              &::placeholder {
                color: #adb5bc;
                font-size: 16px;
                line-height: 163.15%;
              }
            }
          }

          .a1 {
            margin-top: 10px;

            label {
              display: block;
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }

            select.role {
              margin-bottom: 0.5rem;
              width: 100%;
              border: 0;
              padding: 14px;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;
              color: #adb5bc;
              font-size: 16px;
            }
          }

          .location {
            width: 100%;
            display: flex;
            gap: 12px;

            .loc {
              flex: 1;

              label {
                display: block;
                font-weight: 600;
                font-size: 16px;
                line-height: 163.15%;
                color: #25313c;
              }

              select.role {
                margin-bottom: 0.5rem;
                width: 100%;
                border: 0;
                padding: 14px;
                background: #ffffff;
                border: 1px solid #adb5bc;
                border-radius: 8px;
                color: #adb5bc;
                font-size: 16px;
              }
            }

            .number {
              flex: 1;

              label {
                display: block;
                font-weight: 600;
                font-size: 16px;
                line-height: 163.15%;
                color: #25313c;
              }

              input {
                padding: 12px;
                border: 0;

                outline: none;
                width: 100%;
                background: #ffffff;
                border: 1px solid #adb5bc;
                border-radius: 8px;

                &::placeholder {
                  color: #adb5bc;
                  font-size: 16px;
                  line-height: 163.15%;
                }
              }
            }
          }

          .button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              color: #ffffff;
              margin-top: 15px;
              border: 0;
              width: 100%;
              outline: none;
              align-items: center;
              padding: 10px;
              gap: 10px;
              width: 525px;
              height: 52px;
              background: #128c7e;
              border-radius: 8px;

              a {
                text-decoration: none;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}