#AddNewCustomer {
  a {
    text-decoration: none;
    color: black;

  }

  span {
    color: red;
  }

  background: rgba(253, 253, 253, 0.98);
  display: flex;
  align-items: center;

  .formHolderContact {
    margin-bottom: 3rem;
    padding: 2rem;
    width: 100%;
    background-color: white;
    height: 100%;
    padding: 1.2rem;

    .btnHolder {
      width: 100%;
      align-items: center;
      justify-content: center;

      button {
        margin-top: 1rem;
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #128c7e;
        color: #128c7e;
        outline: none;
        padding: 10px;
        border-radius: 8px;
        a{
          color: #128c7e;
        }
      }
    }

    .titleHolder {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 25px;

      h1 {
        text-align: center;
      }

      p {
        width: 50%;
        text-align: center;
        margin-bottom: 2rem;
      }
    }

    .right {
      padding-left: 40px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .select {
        label {
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 163.15%;
          color: #25313c;
        }

        select.role {
          margin-bottom: 0.5rem;
          width: 100%;
          border: 0;
          padding: 14px;
          background: #ffffff;
          border: 1px solid #adb5bc;
          border-radius: 8px;
          color: #adb5bc;
          font-size: 16px;
        }
      }
    }

    .left {
      padding-right: 40px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;

      .check {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -13px;
        bottom: 0;

        .checking {
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: flex-end;
          position: relative;
          top: 25px;

          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 163.15%;
            color: #707173;
          }
        }
      }

      .select {
        label {
          display: block;
          font-weight: 600;
          font-size: 16px;
          line-height: 163.15%;
          color: #25313c;
        }

        select.role {
          margin-bottom: 0.5rem;
          width: 100%;
          border: 0;
          padding: 14px;
          background: #ffffff;
          border: 1px solid #adb5bc;
          border-radius: 8px;
          color: #adb5bc;
          font-size: 16px;
        }
      }
    }

    .address {
      margin-top: 4rem;

      .tab {
        margin: 0 auto;
        width: 85%;
        background-color: #e1e1e1;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .tabActive {
          background-color: #ffffff;
          border-bottom: 2px solid #128c7e;
          color: #128c7e;

          a {
            text-decoration: none;
            color: #128c7e;
          }
        }

        ul {
          display: flex;
          list-style: none;
          align-items: center;
          justify-content: space-between;
          padding-left: 0;

          li {
            display: flex;
            padding: 7px;
            align-items: center;
            /* Body Text/P1/Medium 18px */
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            color: #1c1e21;
          }
        }
      }

      .addy {
        margin-top: 2rem;
      }

      .contact {
        .dividedForm {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;

          .select {
            width: 100%;

            select.role {
              margin-top: 0.3rem;
              width: 100%;
              border: 0;
              padding: 15px;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;
              color: #adb5bc;
              font-size: 16px;
            }
          }
        }
      }

      .paymentMethod {
        width: 100%;

        .Exchange {
          .box-circl-holder {
            display: flex;
            align-items: center;
            gap: 10px;

            input {
              width: 20px;
            }

            span {
              color: #707173;
            }

            .text {
              width: 45%;
            }
          }
        }

        .balance {
          width: 100%;

          lable {
            font-weight: 600;
            font-size: 16px;
            line-height: 163.15%;
            color: #25313c;
          }

          input {
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 12px;
            border: 0;
            outline: none;
            width: 100%;
            background: #ffffff;
            border: 1px solid #adb5bc;
            border-radius: 8px;

            &::placeholder {
              color: #adb5bc;
              font-size: 16px;
              line-height: 163.15%;
            }
          }
        }
      }

      .note {
        width: 100%;

        label {
          font-weight: 600;
          font-size: 16px;
          line-height: 163.15%;
          color: #25313c;
        }

        .noteHolder {
          width: 100%;

          textarea {
            width: 100%;
            height: 200px;
            outline: none;
            border: 1px solid #aaa7a7;
            border-radius: 8px;
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 12px;
            border: 0;
            outline: none;
            width: 100%;
            background: #ffffff;
            border: 1px solid #adb5bc;
            border-radius: 8px;

            &::placeholder {
              color: #adb5bc;
              font-size: 16px;
              line-height: 163.15%;
            }
          }
        }
      }

      .attachments {
        width: 100%;

        label {
          font-weight: 600;
          font-size: 16px;
          line-height: 163.15%;
          color: #25313c;
        }

        .uploadHolder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 200px;
          border: 1px dashed #adb5bc;
          border-radius: 8px;

          input {
            width: 20%;
            background-color: transparent;
            border: none;

            &[type="file"] {
              background-color: transparent;
              color: #adb5bc;
              font-size: 16px;
              line-height: 163.15%;
            }
          }
        }
      }

      .buttoning {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cancle {
          background-color: transparent;
          color: black;
          border: 1px solid #128c7e;
          margin-left: 1rem;
        }

        button {
          background-color: #128c7e;
          color: white;
          outline: none;
          border: 0;
          padding: 10px;
          width: 10%;
          border-radius: 8px;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}