#Contact {
  .contactHome {
  }
  .contactFormHolder {
    background: #ffffff;
    width: 100%;
    margin-bottom: 2rem;
    .left {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .address {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
          /* Color/Text/Grey/100 */
          color: #1c1e21;
        }
        p {
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #707173;
        }
      }
      .phone {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
          /* Color/Text/Grey/100 */
          color: #1c1e21;
        }
        p {
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #707173;
        }
      }
      .email {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
          /* Color/Text/Grey/100 */
          color: #1c1e21;
        }
        p {
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #707173;
        }
      }
    }
    .right {
      padding: 1.5rem 1rem 2rem 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;

      h1 {
        text-align: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 38px;
        color: #1c1e21;
      }
      .formHolder {
        form {
          .mail {
            display: flex;
            gap: 12px;
            .number {
              flex: 1;
              label {
                display: block;
                font-weight: 600;
                font-size: 16px;
                line-height: 163.15%;
                color: #25313c;
              }
              input {
                padding: 12px;
                border: 0;

                outline: none;
                width: 100%;
                background: #ffffff;
                border: 1px solid #adb5bc;
                border-radius: 8px;
                &::placeholder {
                  color: #adb5bc;
                  font-size: 16px;
                  line-height: 163.15%;
                }
              }
            }
          }

          .a1 {
            margin-top: 10px;
            label {
              display: block;
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }
            select.role {
              margin-bottom: 0.5rem;
              width: 100%;
              border: 0;
              padding: 14px;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;
              color: #adb5bc;
              font-size: 16px;
            }
          }
          .location {
            width: 100%;
            display: flex;
            gap: 12px;
            .loc {
              flex: 1;
              label {
                display: block;
                font-weight: 600;
                font-size: 16px;
                line-height: 163.15%;
                color: #25313c;
              }
              select.role {
                margin-bottom: 0.5rem;
                width: 100%;
                border: 0;
                padding: 14px;
                background: #ffffff;
                border: 1px solid #adb5bc;
                border-radius: 8px;
                color: #adb5bc;
                font-size: 16px;
              }
            }
            .number {
              flex: 1;
              label {
                display: block;
                font-weight: 600;
                font-size: 16px;
                line-height: 163.15%;
                color: #25313c;
              }
              input {
                padding: 12px;
                border: 0;

                outline: none;
                width: 100%;
                background: #ffffff;
                border: 1px solid #adb5bc;
                border-radius: 8px;
                &::placeholder {
                  color: #adb5bc;
                  font-size: 16px;
                  line-height: 163.15%;
                }
              }
            }
          }
          .textArea {
            margin-top: 0.3rem;
            textarea {
              width: 100%;
              height: 150px;
              border: 1px solid #adb5bc;
              padding: 0.5rem;
              border-radius: 8px;
              &::placeholder {
                color: #a4a5a6;
              }
            }
          }
          .button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              margin-top: 15px;
              border: 0;
              width: 100%;
              outline: none;
              align-items: center;
              padding: 10px;
              gap: 10px;
              width: 525px;
              height: 52px;
              background: #128c7e;
              border-radius: 8px;
              a {
                color: #ffffff;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
