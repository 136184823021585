#Register {
  background-image: url("../assets/Landing\ page.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .container {
    .row {
      .left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .contentHolder {
          display: flex;
          width: 100%;
          gap: 20px;
          margin-bottom: 2rem;
         
          .text {
            h1 {
              font-weight: 800;
              font-size: 25px;
              line-height: 30px;
              color: #25313c;
            }
            p {
              width: 390px;
              font-weight: 600;
              font-size: 14px;
              line-height: 163.15%;
              color: #6d7d8b;
            }
          }
        }
        .tabNav {
          margin-top: 2rem;
          display: flex;
          gap: 20px;
          p {
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #6d7d8b;
          }
        }
      }
      .right {
        .loginHolder {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
            0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
          width: 500px;
          padding-top: 30px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 30px;
          h1 {
            font-weight: 800;
            font-size: 30px;
            line-height: 50px;
            text-align: center;
            color: #25313c;
            margin-bottom: 1rem;
          }
          .act {
            background: #ffffff;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
              0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
            border-bottom: 2px solid #128c7e;
          }
          .navTabs {
            width: 97%;
            background: #dae3ea;

            ul {
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style: none;
              padding: 0;
              li {
                flex: 1;
                margin-right: 0.8rem;
                padding: 0.5rem;
                padding-left: 1rem;
                a {
                  text-decoration: none;
                  color: #25313c;
                }
              }
            }
          }
          .formHolder {
            margin-top: 1.5rem;
            form {
              .mail {
                display: flex;
                gap: 12px;
               .number {
                  flex: 1;
                  label {
                    display: block;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 163.15%;
                    color: #25313c;
                  }
                  input {
                    padding: 12px;
                    border: 0;

                    outline: none;
                    width: 100%;
                    background: #ffffff;
                    border: 1px solid #adb5bc;
                    border-radius: 8px;
                    &::placeholder {
                      color: #adb5bc;
                      font-size: 16px;
                      line-height: 163.15%;
                    }
                  }
                }
              }

              .a1 {
                margin-top: 10px;
                label {
                  display: block;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 163.15%;
                  color: #25313c;
                }
                select.role {
                  margin-bottom: 0.5rem;
                  width: 100%;
                  border: 0;
                  padding: 14px;
                  background: #ffffff;
                  border: 1px solid #adb5bc;
                  border-radius: 8px;
                  color: #adb5bc;
                  font-size: 16px;
                }
              }
              .location {
                width: 100%;
                display: flex;
                gap: 12px;
                .loc {
                  flex: 1;
                  label {
                    display: block;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 163.15%;
                    color: #25313c;
                  }
                  select.role {
                    margin-bottom: 0.5rem;
                    width: 100%;
                    border: 0;
                    padding: 14px;
                    background: #ffffff;
                    border: 1px solid #adb5bc;
                    border-radius: 8px;
                    color: #adb5bc;
                    font-size: 16px;
                  }
                }
                .number {
                  flex: 1;
                  label {
                    display: block;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 163.15%;
                    color: #25313c;
                  }
                  input {
                    padding: 12px;
                    border: 0;

                    outline: none;
                    width: 100%;
                    background: #ffffff;
                    border: 1px solid #adb5bc;
                    border-radius: 8px;
                    &::placeholder {
                      color: #adb5bc;
                      font-size: 16px;
                      line-height: 163.15%;
                    }
                    &:focus{
                      outline: #128c7e;
                      border: 2px solid #128c7e;
                    }
                  }
                }
              }
              .check {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                p {
                  font-size: 14px;
                  line-height: 163.15%;
                  color: #707173;
                  position: relative;
                  top: 10px;
                }
              }
              .button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                  margin-top: 15px;
                  border: 0;
                  width: 100%;
                  outline: none;
                  align-items: center;
                  padding: 10px;
                  gap: 10px;
                  width: 525px;
                  height: 52px;
                  background: #128c7e;
                  border-radius: 8px;
                  a {
                    color: #ffffff;
                    text-decoration: none;
                  }
                }
              }
              .dont {
                margin-top: 1rem;
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 163.15%;

                color: #25313c;
              }
            }
          }
        }
      }
    }
  }
}
