#PriceCard {
  .priceCardHolder {
    .priceIng {
      padding: 0.7rem;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .top {
        margin-top: 1rem;
        h3 {
          font-weight: 600;
          font-size: 24px;
          line-height: 163.15%;
          display: flex;
          align-items: center;
          text-align: center;
          margin-bottom: 1rem;
          color: #000000;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 163.15%;
          color: #000000;
          margin-top: 2rem;
        }
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: 163.15%;
          color: #000000;
        }
      }
      .middle {
        margin-top: 1rem;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        color: #6d7d8b;
        p {
          width: 236px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 152.34%;
        }
        .pricebuttonHolder {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-top: 2rem;
          button {
            padding: 10px;
            background: #128c7e;
            border-radius: 8px;
            border: none;
            outline: none;
            color: #ffffff;
          }
        }
      }
      .bottom {
        margin-top: 1rem;
        ul {
          list-style: none;
          padding-left: 0;
          li {
            margin-top: 10px;
            display: flex;
            align-items: center;
            height: 20;
            gap: 15px;
            font-weight: 400;
            font-size: 13px;
            line-height: 29px;
            display: flex;
            align-items: center;

            /* Grey */
            color: #6d7d8b;
          }
        }
      }
    }
  }
}
