#ButtomNav {
  width: 100%;
  background-color: #273443;
  nav {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      gap: 20px;
      button {
        background-color: transparent;
        border: 1px solid white;
        padding: 8px;
        color: white;
        border-radius: 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
      }
    }
    .middle {
      ul {
        width: 100%;
        display: flex;
        list-style: none;
        height: inherit;
        align-items: center;
        gap: 10px;
        span {
          width: 1px;
          background-color: white;
          height: 20px;
        }
        li {
          color: white;
          cursor: pointer;
        }
      }
    }
    .right {
      display: flex;
      gap: 20px;
      button {
        background-color: transparent;
        border: 1px solid white;
        padding: 8px;
        color: white;
        border-radius: 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
      }
      .save {
        border: none;
        background: #128c7e;
      }
    }
  }
}
