nav.navbar {
  padding-left: 2rem;
  padding-right: 2rem;

  z-index: 1;
  a {
    color: #6d7d8b;
    font-weight: 700;
  }
  ul {
    margin-left: 2rem;
    display: flex;
    gap: 20px;
    li.nav-item {
      gap: 58px;
    }
  }
.activate{
  color: #128c7e;
}
  .last {
    a {
      color: #6d7d8b;
    }
    gap: 25px !important;
    align-items: center;
    .createAccount {
      outline: none;
      border: 0;
      
      a {
        text-decoration: none;
        padding: 10px 16px;
        gap: 10px;
        background: #128c7e;
        border-radius: 4px;
        color: white;
      }
    }
  }
}
