#Cards {
  display: flex;
  align-items: center;
  justify-content: center;
  .row {
    width: 100%;
    align-items: center;
    justify-content: center;
    border: none !important;
    .cardHolder {
      margin: 0.5rem;
      padding: 0.5rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 31%;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      .title {
        h1 {
          font-size: 22px;
          font-weight: 700;
          color: #25313c;
          margin-top: 0.5rem;
        }
      }
      .list {
        ul {
          display: flex;
          flex-direction: column;
          gap: 7px;
          li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 163.15%;
          }
        }
      }
      .buttonHolder {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          outline: none;
          border: 1px solid #25313c71;
          color: #128c7e;
          background-color: #ffffff;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          border-radius: 8px;
          padding: 10px;
          width: 50%;
        }
      }
    }
    .seeAllFeatures {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      button {
        width: 30%;
        background: #128c7e;
        border-radius: 8px;
        padding: 12px;
        border: 0;
        color: #ffffff;
      }
    }
  }
}
#SupportCard {
  .listCard {
    padding: 2rem;
    .listCards {
      justify-content: space-evenly;
      align-items: center;
      gap: 30px;
      .support-card {
        margin-bottom: 2rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        background: #ffffff;
        width: 340px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
          0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        gap: 20px;
        .imgHolder {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #1c1e21;
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #707173;
        }
        .btnHolder {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          button {
            outline: none;
            border: 0;
            padding: 10px;
            width: 70%;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            border-radius: 8px;
          }
          .one {
            background: #128c7e;
            color: white;
          }
          .two {
            border: 1px solid #707173;
            background-color: white;
            color: #128c7e;
          }
        }
      }
    }
  }
}
