.headerHolder {
  background-image: url("../assets/Secondary\ Background.png");
  height: 350px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  h1 {
    width: 100%;
    max-width: 45%;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #1c1e21;
  }
  p {
    margin-top: 1rem;
    width: 40%;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #707173;
  }
}
