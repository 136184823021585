#FormInput {
  .lables {
    lable {
      font-weight: 500;
      font-size: 16px;
      line-height: 163.15%;
      color: #25313c;
    }
  }
  label {
    font-size: 16px;
    line-height: 163.15%;
    color: #25313c;
    font-weight: 500;
  }
  input {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 12px;
    border: 0;
    outline: none;
    width: 100%;
    background: #ffffff;
    border: 1px solid #adb5bc;
    border-radius: 8px;
    &::placeholder {
      color: #adb5bc;
      font-size: 16px;
      line-height: 163.15%;
    }
    &:focus{
      border: 2px solid #128c7e;
    }
  }
  .select {
    width: 100%;
    label {
      display: block;
      font-size: 16px;
      line-height: 163.15%;
      color: #25313c;
    }
    select.role {
      margin-bottom: 0.5rem;
      width: 100%;
      border: 0;
      padding: 14px;
      background: #ffffff;
      border: 1px solid #adb5bc;
      border-radius: 8px;
      color: #adb5bc;
      font-size: 16px;
    }
  }
}
