#UserNavbar {
  .fsLayer {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #273443;

    .left-user-holder {
      display: flex;
      gap: 20px;
      align-items: center;
      .search-box {
        color: #ffffff;
        border: 1px solid white;
        padding: 5px;
        width: 100%;
        border-radius: 5px;
        .icon {
          margin-left: 5px;
        }
        input {
          outline: none;
          border: none;
          background-color: transparent;
          color: white;
          &::placeholder {
            color: #ffffff;
            margin-left: 5px;
          }
        }
      }
      .line {
        width: 1px;
        background-color: white;
        height: 30px;
      }
      span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        color: #ffffff;
      }
    }
    .rightPlaceholder {
      display: flex;
      align-items: center;
      gap: 25px;
      span {
        color: #ffffff;
      }
    }
  }
  .secondLayer {
    background-color: #ffffff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    nav {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left {
        display: flex;
        gap: 25px;
        li {
          list-style: none;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #1c1e21;
        }
      }
      .right {
        margin-right: 20px;
        display: flex;
        gap: 10px;
        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          color: #1c1e21;
        }
      }
    }
  }
}
