#Pricing {
  .select {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 40px;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 163.15%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #46484a;
    }
    ul {
      margin-left: 5px;
      list-style: none;
      display: flex;
      padding: 0;
      gap: 20px;
      border: 1px solid #46484a44;
      border-radius: 32px 32px;
      text-align: center;
      li {
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0.5rem;
      }
      .act {
        background: #9de6dd;
        border: 0;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 32px 32px;
      }
    }
  }
  .pricingHolder {
    .switch {
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      .icon {
        color: #128c7e;
        font-size: 50px;
      }
      span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 163.15%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #1c1e21;
      }
    }
  }
  .pricingSection {
    margin-bottom: 4rem;
  }
  .table {
    h1 {
      text-align: center;
    }
  }
}
