* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p {
    margin-bottom: 0;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0 !important;
}
ol, ul {
    padding-left: 0 !important;
}
.navHolder {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    overflow-x: scroll;
}

.inccs {
    width: 20px !important;
}

.long {
    background-color: #128c7e;
}

.want {
    width: 100%;
    background-color: #1c1e2104;
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;

    .one {
        gap: 10px;

        span {
            color: #128c7e;
        }
    }

    .two {
        width: 170px;
        display: flex;
        gap: 10px;
        align-items: center;

        input {
            width: 70px !important;
        }

        button {
            padding: 8px !important;
            border-radius: 10px;
            height: 50px;
        }
    }
}

.lines {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;

    span {
        width: 100%;
        border: 1px solid #1c1e2136;
    }
}

.yearBox {
    display: flex;
    justify-content: space-between;

    .box {
        width: 50px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.11);
    }
}

.tax {
    width: 90%;
    margin: 0 auto;

    .aboutTax {
        background: #FFFFFF;
        border: 1px solid #D2D2D3;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 1rem;

        .topNav {
            display: flex;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
                gap: 10px;

                span {
                    border: 1px solid #D2D2D3;
                    padding-left: 10px;
                    padding-right: 10px;
                    width: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                }
            }

            .right {
                button {
                    border: 1px solid #D2D2D3;
                }
            }
        }

        .content {
            width: 95%;
            margin: 0 auto;

            p {
                margin-top: 1rem;
                font-family: 'Roboto';
                font-size: 16px;
                line-height: 163.15%;

                color: #1c1e217e;
            }
        }
    }
}

body {
    font-family: 'Roboto';
    overflow-x: hidden;
}
input:focus{
  border: 2px solid #128c7e;
}
select{
    outline: #128c7e;
}
.switchs {
    align-items: center;
    justify-content: center;

    .icon {
        color: #128c7e;
        font-size: 50px;
    }

    span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 163.15%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #1c1e21;
    }
}

.mobile {
    display: none;
}
.payNav {
    background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
            0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
    nav{
        ul{
            padding-left: 10px !important;
            display: flex;
            list-style: none;
            gap: 20px;
            padding: 10px;
            li{
                a{
                    text-decoration: none;
                    color: gray;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    #ListUsers .main .header {
        height: 100% !important;
        flex-direction: column;
    }
    #ListUsers .main .header .right {
        flex-direction: column;
    }
    #ListUsers .secondLayer .middle .setting {
        justify-content: space-between !important;
    }
    #ListUsers .main .header .left h1 {
        font-size: 23px !important;
    }
    nav.navbar {
        padding: 0;
    }
        #CustomersDashBoard .secondLayer .left .heading {
            display: block !important;
        }
#CustomersDashBoard .secondLayer .left .heading .setting {
    justify-content: space-between;
}
    .mobile {
        display: block;
    }

    #UserNavbar .fsLayer .left-user-holder .line {
        display: none !important;
    }

    #UserNavbar .fsLayer {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        padding: 0.5rem;
    }

    #UserNavbar .fsLayer .left-user-holder {
        img {
            width: 50px;
        }
    }

    #Home {
        overflow: hidden;

        .home {
            height: 500px;

            .mainContent {
                margin-top: 3rem;

                .title {
                    h1 {
                        width: 100%;
                        font-size: 28px;
                        line-height: 50px;
                    }
                }

                .subTitle {
                    p {
                        width: 97%;

                    }
                }

                .buttonHolder {
                    button.btnOne {
                        width: 60%;
                    }
                }


            }
        }

        .windowHolder {
            position: relative;
            top: 1rem;
            padding: 1rem;

            img {
                width: 100%;
                height: 100%;

            }
        }

        .BringFinancials {
            top: 2rem;

            h1 {
                width: 90%;
                text-align: center;
                font-size: 30px;
                line-height: 50px;

            }

            p {
                width: 100%;
                font-size: 18px;
            }
        }

        .cardBody {
            margin-bottom: 2rem;
            padding: 1rem;
        }
    }

    #Cards {
        margin-top: 3rem;

        .cardHolder {
            width: 100% !important;
            margin-bottom: 1rem;
        }

    }

    .planHolder {
        padding: 1rem;

        .plans {
            padding: 1rem;

            .planTitleHolder {
                h1 {
                    font-size: 30px;
                }

                p {
                    width: 100% !important;
                    text-align: center;
                    font-weight: 400 !important;
                }
            }
        }

        .priceHolder {
            top: -550px !important;
        }
    }

    .tryDemo {
        top: -400px !important;
        padding: 1rem;

        .right {
            h1 {
                margin-top: 2rem;
                font-size: 35px !important;
                width: 100% !important;
                text-align: center;
            }
        }

        .left {
            height: 40vh !important;
        }
    }

    .faq {
        h1 {
            font-size: 40px;
            text-align: center;
            font-weight: 500;
        }
    }

    #Cards .row .seeAllFeatures {
        margin-top: 2rem;
    }

    #Cards .row .seeAllFeatures button {
        width: 100%;
    }

    #Features {
        .cards {
            padding: 2rem;
        }

        .cardHolder {
            width: 100% !important;
            margin-bottom: 2rem;
        }

        .divider {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
        }
    }

    #Pricing {
        .small {
            font-size: 12px !important;
        }

        h1 {
            font-size: 30px !important;
            line-height: 50px;
        }

        ul {
            li {
                font-size: 12px !important;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
                display: flex;
                align-items: center;
            }
        }

        .table {
            overflow: scroll;
        }
    }

    .headerHolder {
        height: 250px !important;
        padding: 1rem;
        margin-top: 2rem !important;

        h1 {
            max-width: 100% !important;
            font-size: 35px !important;
        }

        p {
            width: 100% !important;
        }
    }

    #Partners {
        .partnersHeading {
            padding-top: 10px !important;
            margin-bottom: 1rem !important;
        }

        .formHolder {
            margin-top: 1rem;

            .innerFormHolder {
                .formHolder {
                    margin-bottom: 2rem !important;

                    form {
                        width: 100% !important;
                        padding: 1rem !important;

                        h1 {
                            font-size: 30px !important;
                        }
                    }
                }
            }
        }
    }

    #Online {
        overflow: hidden;

        .online-contact-divider {
            width: 100% !important;

            .left {
                .listHolder {
                    width: 100% !important;

                    .box {
                        width: 100% !important;
                        margin-left: 0px !important;

                        span {
                            width: 55% !important;
                            font-size: 15px !important;
                        }
                    }
                }
            }

            .righFormHolder {
                margin-top: 2rem !important;
                padding: 1rem !important;
                display: flex;
                width: 100%;

                .right {

                    width: 100% !important;
                    max-width: 350px !important;
                }

                h1 {
                    font-size: 25px !important;
                    text-align: center;
                }

                .formHolder {
                    padding: 1rem;

                }
            }
        }
    }

    #Login .loginHolder {
        width: 100% !important;
    }

    #Register .container .row .right .loginHolder {
        width: 100% !important;
    }

    #Register {
        height: 100% !important;
    }

    #Register .container .row .left .contentHolder .text p {
        width: 100% !important;
    }

    #Register .container .row .right .loginHolder .navTabs ul li {
        padding: 0 !important;
    }

    #ForgotPassword .loginHolder {
        width: 100% !important;
    }

    #Footer {
        padding: 1rem;
    }

    #CustomersDashBoard .fsLayer .navHolder {
        overflow: scroll;
    }

    #CustomersDashBoard .fsLayer h1 {
        font-size: 30px !important;
    }

    #CustomersDashBoard .fsLayer .navHolder .boxes {
        padding: 10px;
    }

    #CustomersDashBoard {
        padding: 1rem;
    }

    #CustomersDashBoard .secondLayer .right .innerHolder {
        margin-top: 0.5rem;
    }
#CustomersDashBoard .fsLayer {
    padding-top: 10px ;
}
    #UserNavbar .fsLayer .rightPlaceholder {
        display: none !important;
    }

    #UserNavbar .fsLayer .left-user-holder .search-box {
        display: none !important;
    }

    #UserNavbar .fsLayer .rightPlaceholder span {
        // display: none !important;
    }

    .row>* {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    #CustomersDashBoard .secondLayer .left {
        padding-left: .5rem !important;
        padding-right: 0.5rem !important;
    }

    #CustomersDashBoard .secondLayer {
        padding-bottom: 2rem !important;
    }

    #CustomersDashBoard .tabling .heading {
        display: block !important;
    }

    #CustomersDashBoard .tabling {
        padding: .5rem !important;
    }

    #CustomersDashBoard .tabling .heading h1 {
        font-size: 20px !important;
        text-align: center;
    }

    #CustomersDashBoard .tabling .heading .setting {
        justify-content: center;
        margin-top: 1rem;
    }

    #Login .tabNav {
        gap: 10px !important;
    }

    #AddNewCustomer .formHolderContact .titleHolder p {
        width: 100% !important;
    }

    #AddNewCustomer .formHolderContact .btnHolder button {
        margin-bottom: 2rem;
    }

    #AddNewCustomer .formHolderContact .address {
        margin-top: 10px !important;
    }

    #AddNewCustomer .formHolderContact .address .tab {
        width: 100% !important;
    }

    #AddNewCustomer .formHolderContact .address .tab ul {
        display: block !important;
    }

    #AddNewCustomer .formHolderContact .address .buttoning button {
        width: 100% !important;
    }

    #AddNewCustomer .formHolderContact .address .buttoning {
        gap: 15px;
    }

    #ListUsers {
        padding: 10px !important;
    }

    #ListUsers .main .header .left h1 {
        font-size: 18px !important;
    }

    #ListUsers .secondLayer {
        display: block !important;
    }
    #Invoice .Invoice-container .invoice-header {
        flex-direction: column;
    }
    #Invoice .Invoice-container .main .header .left h1 {
        font-size: 22px!important;
    }
    #Invoice .Invoice-container .main .header .inputHolder {
        display: block;
        gap: 10px !important;
    }
    #Invoice .Invoice-container .main .header {
        flex-direction: column;
    }
    #ListUsers .secondLayer .middle .search {
        display: none !important;
    }
    #ListUsers .secondLayer .middle {
        display: block !important;
    }
    .search div {
        width: 100%;
    }
    #ListUsers .secondLayer .middle .setting {
        margin-top: 25px;
    }
    #ListUsers .tableSection .nav {
        display: block !important;
    }
    #ListUsers .tableSection .nav li {
        width: 100% !important;
    }
    #ListUsers .secondLayer .middle .setting {
        width: 100% !important;
    }
    .p-2 {
        overflow: scroll;
    }
    #ListUsers .tableSection .paginate {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    #ListUsers .tableSection .paginate nav span{
        margin-top: 20px;
    }

    #Invoice .Invoice-container .main .filter .left {
        // display: block !important;
    }
    #Invoice .Invoice-container .main .button {
        justify-content: space-between;
    }
    #Invoice .Invoice-container .main .button .create {
        width: 100% !important;
    }
    #Invoice .Invoice-container .main .button .delete {
        width: 100% !important;
    }
    .Message{
        width: 100% !important;
    }
    .upload .file {
        width: 100% !important;
        flex-direction: row;
    }
    #Invoice .Invoice-container .main form .tax-rate .radios {
        display: block !important;
    }
    .table{
        overflow: scroll !important;
    }
    #Invoice .Invoice-container .main .upload .file {
        flex-direction: row !important;
    }
    #Invoice .Invoice-container .main .header .right h1 {
        font-size: 20px;
    }
    .navHolder {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    #AddNewCustomer .formHolderContact .titleHolder {
        gap: 10px;
    }
    #CustomersDashBoard .fsLayer {
        padding: 0;
        gap: 0;
    }
    .heading .date .inputDate {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 1rem;
    }
    #Invoice .note {
        width: 100%;
    }
    #CustomersDashBoard .fsLayer {
        gap: 0;
    }
    #CustomersDashBoard .secondLayer .left .heading .date {
        display: block;
    }
    #CustomersDashBoard .secondLayer {
        overflow-x: hidden;
    }
    #CustomersDashBoard .table {
        overflow: scroll !important;
        width: 1200px !important;
    }
    #CustomersDashBoard .secondLayer .left {
    }
    #Invoice .Invoice-container .main .header .inputHolder {
        display: block !important;
    }
    #UserNavbar .secondLayer {
        display: none !important;
    }
}