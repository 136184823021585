#Invoice {
  background: #fcfbfb;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  .Invoice-container {
    margin-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
    background-color: white;

    .invoice-header {
      padding: 1rem 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);

      .top {
        margin-left: 1rem;

        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          color: #1c1e21;
        }
      }

      .right {
        margin-right: 1rem;

        .top {
          button {
            outline: none;
            border: 0;
            padding: 0.5rem;
            font-family: "Roboto", serif;
            color: #ffffff;
            background: #128c7e;
            border-radius: 8px;
          }
        }
      }
    }



    .main {
      width: 90%;
      justify-content: center;
      border-radius: 50px;
      padding: 10px;
      margin: .5rem auto 0;

      .filter {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          border: 1px solid #128c7e;
        }

        input {
          padding: 5px;
          border: 1px solid #128c7e;
          outline: none;
        }

        .left {
          align-items: center;
          justify-content: center;
          gap: 10px;

          .Period {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        .right {
          display: flex;
          gap: 20px;
          button {
            margin-left: 10px;
            border: 1px solid #128c7e;
          }
        }
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .note {
          display: flex;
          gap: 10px;

          span {
            margin-top: .3rem;
            width: 20px;
            height: 20px;
            border: 1px solid black;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            padding: 10px;
          }
        }

        .left {
          h1 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 28px;
            color: #1c1e21;
          }
        }

        .right {
          h1 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 38px;
          }

          p {
            text-align: end;
          }
        }

        .inputHolder {
          display: flex;
          align-items: center;
          gap: 20px;

          .in-check {
            display: flex;
            gap: 5px;
            align-items: center;

            span {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 163.15%;
              display: flex;
              align-items: center;
              color: #46484a;
            }
          }
        }
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      form {
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
          color: #1c1e21;
        }

        .sub {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            width: 150px;
          }
        }

        .Adjusting {
          .Adjusted {
            display: flex;

            input {
              width: 30px;
            }
          }
        }

        .name {
          .select {
            label {
              display: block;
              font-size: 16px;
              line-height: 163.15%;
              color: #25313c;
            }

            select.role {
              margin-bottom: 0.5rem;
              width: 100%;
              border: 0;
              padding: 14px;
              background: #ffffff;
              border: 1px solid #adb5bc;
              border-radius: 8px;
              color: #adb5bc;
              font-size: 16px;
            }
          }

          .btnHolder {
            width: 100%;
            align-items: center;
            justify-content: center;

            button {
              margin-top: 1rem;
              width: 100%;
              background-color: #ffffff;
              border: 1px solid #128c7e;
              color: #128c7e;
              outline: none;
              padding: 10px;
              border-radius: 8px;
            }
          }
        }

        .email-input {
          .p {
            padding-bottom: 1.5rem;
          }
        }

        .div {
          select.role {
            margin-bottom: 0.5rem;
            width: 100%;
            border: 0;
            padding: 14px;
            background: #ffffff;
            border: 1px solid #adb5bc;
            border-radius: 8px;
            color: #adb5bc;
            font-size: 16px;
          }
        }

        .tax-rate {
          width: 100%;
          display: flex;
          flex-direction: column;

          .radios {
            display: flex;
            align-items: center;
            margin-top: 0.5rem;

            .check {
              input {
                width: 10%;
              }

              display: flex;
              align-items: center;
              gap: 5px;

              span {
                width: 150px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }
      }

      .button {
        display: flex;
        gap: 10px;

        .create {
          color: #128c7e;
          border: 1px solid #128c7e;
          padding: 8px;
          outline: none;
          width: 30%;
        }

        .delete {
          color: #dd4225;
          border: 1px solid #dd4225;
          padding: 8px;
          outline: none;
          width: 30%;
        }
      }

      .Exchange {
        .box-circl-holder {
          display: flex;
          align-items: center;
          gap: 10px;

          input {
            width: 20px;
          }

          span {
            color: #707173;
          }

          .text {
            width: 43%;
          }
        }
      }

      .Message {
        .title {
          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 163.15%;
            color: #1c1e21;
          }
        }

        textarea {
          height: 200px;
          outline: none;
          border: 1px solid #d2d2d3;
          border-radius: 8px;
          color: #303030;
          padding-left: 0.5rem;

          &::placeholder {
            font-family: "Roboto";
            padding: 2rem;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 163.15%;
            color: #d2d2d3;
          }
        }
      }

      .upload {
        width: 100;

        .file {
          width: 75%;
          height: 200px;
          border: 1px dashed #46484a;
          border-radius: 8px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;

        .Total {
          display: flex;
          gap: 30px;

          h4 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
          }

          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;

            input {
              width: 50px;
              outline: none;
            }
          }
        }
      }

      .Process {
        .Message {
          .title {
            span {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 163.15%;
              color: #1c1e21;
            }

            .set {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 163.15%;
              display: flex;
              align-items: center;
              text-decoration-line: underline;
              color: #128c7e;
            }
          }

          textarea {
            height: 130px;
            outline: none;
            border: 1px solid #d2d2d3;
            border-radius: 8px;
            color: #303030;
            padding-left: 0.5rem;

            &::placeholder {
              font-family: "Roboto";
              padding: 2rem;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 163.15%;
              color: #d2d2d3;
            }
          }
        }
      }

      .UnpaidInvoices {
        margin-top: 2rem;

        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;

          /* Color/Text/Grey/100 */
          color: #1c1e21;
        }

        .Unallocate {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .Unallocated {
            width: 30%;

            p {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 21px;

              /* identical to box height, or 131% */
              display: flex;
              align-items: center;

              /* Color/Text/Grey/80 */
              color: #46484a;
            }

            span {
              display: flex;
              border: 1px solid #d2d2d3;
              padding: 10px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  .scrollable {
    margin-top: 2rem;
    height: 500px;
    overflow-x: scroll;
  }

  .total {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
    padding: 1rem;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #1C1E21;
    }
  }

  .ExpensesnavHolder {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding: 5px;

    h3 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #1C1E21;
      padding-top: 5px;
    }

    .Expenses {
      display: flex;
      gap: 50px;
      border-bottom: 2px solid #128c7e;
      align-items: center;
    }

    .Item {
      background: #EBECEE;
      border-bottom: 2px solid #D2D2D3;
      display: flex;
      gap: 80px;
      padding-left: 10px;
      padding-right: 10px;
      align-items: center;
    }
  }

  .note {
    width: 85%;
    margin: 0 auto;
    display: flex;
    gap: 13px;
    padding: 2rem;

    .icircle {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #1C1E21;
      height: 20px;
      width: 20px;
      padding: 6px;
      border-radius: 50%;
      font-weight: 600;
    }

    span {

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 163.15%;
      color: #1C1E21;
    }
  }

  .notes {
    width: 85%;
    margin: 0 auto;
    display: flex;
    gap: 13px;
    margin-top: 1rem;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #1C1E21;
      height: 20px;
      width: 20px;
      padding: 6px;
      border-radius: 50%;
      font-weight: 600;
      margin-top: 3px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 163.15%;
      color: #1C1E21;
    }
  }

  .deposit {
    gap: 40px;
    h4 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;

      /* identical to box height, or 139% */
      display: flex;
      align-items: center;

      /* Color/Text/Grey/100 */
      color: #1C1E21;
    }

    .Deposit {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .nav {
    width: 95%;
    margin: 0 auto;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d2d2d3;
      width: 10%;
      height: 50px;
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: #d2d2d3;
      border-radius: 10px;
      cursor: progress;
      width: 70px;
    }
  }
}