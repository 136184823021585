#ListUsers {
  background-color: rgba(255, 255, 255, 0.31);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .main {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;

    .header {
      height: 70px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04),
        0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;

      .left {
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          font-feature-settings: "liga" off;
          color: #1c1e21;
        }
      }

      .right {
        display: flex;
        gap: 10px;

        button {
          border: 1px solid #128c7e;
          border-radius: 8px;
          outline: none;
        }

        .btn {
          color: #128c7e;
        }

        .button {
          background-color: #128c7e;
          color: white;
        }
      }
    }
  }

  .secondLayer {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .left {
      .btn {
        border: 1px solid #128c7e;
        padding: 10px;
        border-radius: 8px;
        color: #128c7e;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .middle {
      display: flex;
      gap: 20px;
      align-items: flex-end;

      .search {
        background-color: transparent;
        display: flex;
        align-items: center;

        .icon {
          background-color: #128c7e;
          color: white;
          font-size: 44px;
        }

        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          margin-right: 0.5rem;
          color: #000000;
        }

        div {
          border: 1px solid #aaa7a7;
          padding: 0;
          display: flex;
          align-items: center;
          input {
            border: none;
            outline: none;
            padding: 5px;
            width: 100%;
          }
        }
      }

      .setting {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
        }

        .dropdown {
          button {
            outline: none;
            padding-left: 15px;
            padding-right: 15px;
            border: 1px solid rgb(99, 99, 99);
          }
        }
      }
    }

    .right {
      padding-top: 2rem;

      .youtube {
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #1c1e21;
        }

        img {
          width: 200px;
          height: 100%;
        }
      }
    }

    .tableSection {
      margin-top: 5rem;
    }
  }

  .tableSection {
    width: 100%;

    .nav {
      width: 95%;
      margin: 0 auto;
      padding: 5px;
      display: flex;
      align-items: center;
      gap: 10px;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d2d2d3;
        width: 10%;
        height: 50px;
        text-align: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #d2d2d3;
        border-radius: 10px;
        padding: 5px;
        cursor: progress;
      }
    }

    .paginate {
      padding: 1rem;
      display: flex;
      justify-content: space-between;

      a {
        color: gray;
      }

      .acts {
        a {
          color: #00c5ae;
        }
      }

      nav {
        display: flex;
        flex-direction: column;

        span {
          margin-left: 40%;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;

          /* identical to box height, or 136% */

          /* Color/Text/Grey/100 */
          color: #1c1e21;
        }
      }

      .btn {
        height: 50px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        border: 1px solid #0e9a6e;
        color: #0e9a6e;
      }
    }
  }

  .tableListing {
    padding: 1rem;

    .heading {
      display: flex;
      justify-content: space-between;
      background: #ebecee;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 1rem;
      padding-right: 1rem;

      span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #1c1e21;
      }

    }

    .body {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }
  }

}